













import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { levels } from "@/constants/ocppLog";
import JsonViewer from "vue-json-viewer";
import appService from "@/services/appService";

export default Vue.extend({
  components: {
    JsonViewer,
  },
  data() {
    return {
      self: this,
      chargingStation: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "OCPP Logs",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              time: {
                type: "XTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Time (From)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Time (To)",
                    },
                  },
                },
              },
              level: {
                type: "selectMultiple",
                attrs: {
                  label: "Level",
                  items: makeSelectOptions(levels),
                },
                rules: {
                  in: {},
                },
              },
              action: {
                attrs: {
                  label: "Action",
                },
                rules: {
                  equal_to: {},
                },
              },
              retailerId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Retailer",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("retailers", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              chargingStationUniqueId: {
                attrs: {
                  label: "Charge Point ID",
                },
                rules: {
                  match: {},
                },
              },
            },
          },
          displayFields: {
            time: {
              text: "Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            level: {
              text: "Level",
              sortable: true,
              options: {
                transform: (value) => levels[value],
                label: true,
              },
            },
            module: {
              text: "Module",
              hidden: true,
            },
            method: {
              text: "Method",
              hidden: true,
            },
            action: {
              text: "Action",
              options: {
                label: true,
              },
            },
            message: {
              text: "Message",
              width: "200px",
            },
            details: {
              text: "Details",
            },
            retailer: {
              text: "Retailer",
              options: {
                subProp: "retailer.name",
                label: true,
                sortBy: "retailerId",
              },
            },
            user: {
              text: "User",
              hidden: true,
              options: {
                subProp: "user.name",
                label: true,
                sortBy: "userId",
              },
            },
            chargingStationUniqueId: {
              text: "Charge Point ID",
              sortable: true,
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
          },
          multiSelectActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              content: {
                text: "Export selected",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("chargingSessions", `@/export`, {
                    payload: JSON.stringify({
                      filters: [
                        {
                          key: "_id",
                          operator: "in",
                          value: dataTable.model.value.map((item) => item._id),
                        },
                      ],
                      limit: -1,
                      extraParams: {
                        currentTimeZoneOffset: new Date().getTimezoneOffset() * -1 * 60 * 1000,
                      },
                    }),
                  });
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              content: {
                text: "Export all",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  coreApiClient.download("ocppLogs", `@/export`, dataTable.getFindAllOptions());
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            const activeTenant = appService.getActiveTenant();
            if (activeTenant) {
              options.filters.push({
                key: "tenantId",
                operator: "equal_to",
                value: activeTenant._id,
              });
            }

            const { $route } = this.context();
            if ($route.query.chargingStationUniqueId) {
              options.filters.push({
                key: "chargingStationUniqueId",
                operator: "equal_to",
                value: $route.query.chargingStationUniqueId,
              });
            }

            options.extraParams.currentTimeZoneOffset = new Date().getTimezoneOffset() * -1 * 60 * 1000;

            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("ocppLogs", "findAll", options);
              return [items, count];
            },
          },
        },
      },
    };
  },
  async created() {
    const $route = this.$route;
    if ($route.query.chargingStationUniqueId) {
      const chargingStation = await coreApiClient.call("chargingStations", "findById", {
        uniqueId: $route.query.chargingStationUniqueId,
      });
      this.chargingStation = chargingStation;
    }
  },
});
